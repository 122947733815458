const images = require.context('../images/', true)
const fonts = require.context('../fonts/', true)

window.PPLOG = {};
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import "@hotwired/turbo-rails";
import "../ios_shim";
import "../turbo";

import Rails from '@rails/ujs';
Rails.start();
import 'bootstrap-sass/assets/javascripts/bootstrap';

// TODO: 使っているのか含めて整理したい
import 'vendor/Chart.min';
import 'vendor/cheet.min';
import 'vendor/jquery.hotkeys';
import 'vendor/jquery.onscreen';
import 'vendor/jquery.selection';

import 'application/bootstrap_init';
import 'application/common';
import 'application/esarea';
import 'application/form';
import 'application/following';
import 'application/keyboard_shortcut';
import 'application/mathjax';
import 'application/notification_message';
import 'application/post';
import 'application/star';
import 'application/theme_colors';
import 'application/zapping';
