jQuery(function() {
  var keyboardShortcut;
  keyboardShortcut = new KeyboardShortcut();
});

class KeyboardShortcut {
  constructor() {
    this.init();
  }

  init() {
    this.listen();

    this.faces = [
      "( ˘ω˘)",
      "٩( ˘ω˘ )و",
      "₍₍ (̨̡ ‾᷄⌂‾᷅)̧̢ ₎₎",
      "(εωз)",
      "ポエ＼＼\\ ٩( ˘ω˘ )و //／／ポエ",
      "₍₍ (̨̡ ˘ω˘)̧̢ ₎₎  ₍₍ (̨̡˘ω˘ )̧̢ ₎₎",
      "ヾ(*’ω’*)ﾉﾞ",
      "₍₍ (̨̡ ‾᷄⌂‾᷅)̧̢ ₎₎",
      "|˘ω˘)\""
    ];
  }

  listen() {
    cheet("↑ ↑ ↓ ↓ ← → ← → b a", function() {
      $(".zapping-button a i").addClass("icon-shachihoko");
      $(".zapping-button a").addClass("clicked");

      setTimeout(function() {
        $(".zapping-button a").removeClass("clicked");
      }, 300);
    });

    $(document).on("keydown", "body", e => {
      if (e.metaKey) {
        return;
      }

      if (e.ctrlKey) {
        return;
      }

      if (e.altKey) {
        return;
      }

      if (e.shiftKey) {
        return;
      }

      if ($("input, textarea, selectbox").is(":focus")) {
        return;
      }

      switch (e.key) {
      case 'f':
      case 'z':
        document.querySelector(".zapping-button a")?.click();
        break;
      case 'd':
        document.querySelector("a.post-star:not(.disabled)")?.click();
        break;
      case 'h':
        location.href = "/";
        break;
      case 'n':
        location.href = "/my/posts/new";
        break;
      case 'p':
        $("head title").html(this.faces[Math.floor(Math.random() * this.faces.length)]);
        break;
      case 's':
        document.querySelector("form#follow:not(.hidden) .follow.btn")?.click();
        break;
      case 'j':
        window.scrollBy(0, 15);
        break;
      case 'k':
        window.scrollBy(0, -15);
        break;
      }
    });
  }
}

KeyboardShortcut.prototype.faces = null;
