var suggesting = null;

$(document).on("keydown", ".js-esarea", function(e) {
  var title = $(e.target).val().replace(/\r\n|\r/g, "\n").split("\n")[0];

  if (!title.match(/\.md$/)) {
    return;
  }

  if (suggesting) {
    return;
  }

  switch ((e.which || e.keyCode)) {
  case 9:
    handleTabKey(e);
    break;
  case 13:
    handleEnterKey(e);
    break;
  case 32:
    handleSpaceKey(e);
    break;
  }

  return;
});

var handleTabKey = function(e) {
  var reindentedCount;
  var reindentedText;
  var newPos;
  e.preventDefault();
  var currentLine = getCurrentLine(e);
  var text = $(e.target).val();
  var pos = $(e.target).selection("getPos");

  if (currentLine) {
    $(e.target).selection("setPos", {
      start: currentLine.start,
      end: (currentLine.end - 1)
    });
  }

  if (e.shiftKey) {
    if (currentLine && currentLine.text.charAt(0) === "|") {
      newPos = text.lastIndexOf("|", pos.start - 1);

      if (newPos > 0) {
        $(e.target).selection("setPos", {
          start: newPos - 1,
          end: newPos - 1
        });
      }
    } else {
      reindentedText = $(e.target).selection().replace(/^ {1,4}/gm, "");
      reindentedCount = $(e.target).selection().length - reindentedText.length;

      $(e.target).selection("replace", {
        text: reindentedText,
        mode: "before"
      });

      if (currentLine) {
        $(e.target).selection("setPos", {
          start: pos.start - reindentedCount,
          end: pos.start - reindentedCount
        });
      }
    }
  } else if (currentLine && currentLine.text.charAt(0) === "|") {
    newPos = text.indexOf("|", pos.start + 1);

    if ((newPos < 0) || (newPos === text.lastIndexOf("|", currentLine.end - 1))) {
      $(e.target).selection("setPos", {
        start: currentLine.end,
        end: currentLine.end
      });
    } else {
      $(e.target).selection("setPos", {
        start: newPos + 2,
        end: newPos + 2
      });
    }
  } else {
    $(e.target).selection("replace", {
      text: "    " + $(e.target).selection().split("\n").join("\n    "),
      mode: "before"
    });

    if (currentLine) {
      $(e.target).selection("setPos", {
        start: pos.start + 4,
        end: pos.start + 4
      });
    }
  }

  $(e.target).trigger("input");
};

var handleEnterKey = function(e) {
  var prevLine;
  var row;
  var currentLine;

  if (e.metaKey || e.ctrlKey || e.shiftKey) {
    return;
  }

  if (!(currentLine = getCurrentLine(e))) {
    return;
  }

  if (currentLine.start === currentLine.caret) {
    return;
  }

  if (match = currentLine.text.match(/^(\s*(?:-|\+|\*) (?:\[(?:x| )\] )?)\s*\S/)) {
    if (currentLine.text.match(/^(\s*(?:-|\+|\*) (?:\[(?:x| )\] ))\s*$/)) {
      $(e.target).selection("setPos", {
        start: currentLine.start,
        end: (currentLine.end - 1)
      });

      return;
    }

    e.preventDefault();

    $(e.target).selection("insert", {
      text: "\n" + match[1],
      mode: "before"
    });
  } else if (currentLine.text.match(/^(\s*(?:-|\+|\*) )/)) {
    $(e.target).selection("setPos", {
      start: currentLine.start,
      end: (currentLine.end)
    });
  } else if (currentLine.text.match(/^.*\|\s*$/)) {
    if (currentLine.text.match(/^[\|\s]+$/)) {
      $(e.target).selection("setPos", {
        start: currentLine.start,
        end: (currentLine.end)
      });

      return;
    }

    if (!currentLine.endOfLine) {
      return;
    }

    e.preventDefault();
    row = [];

    for (var match of currentLine.text.match(/\|/g)) {
      row.push("|");
    }

    prevLine = getPrevLine(e);

    if (!prevLine || (!currentLine.text.match(/---/) && !prevLine.text.match(/\|/g))) {
      $(e.target).selection("insert", {
        text: "\n" + row.join(" --- ") + "\n" + row.join("  "),
        mode: "before"
      });

      $(e.target).selection("setPos", {
        start: currentLine.caret + 6 * row.length - 1,
        end: currentLine.caret + 6 * row.length - 1
      });
    } else {
      $(e.target).selection("insert", {
        text: "\n" + row.join("  "),
        mode: "before"
      });

      $(e.target).selection("setPos", {
        start: currentLine.caret + 3,
        end: currentLine.caret + 3
      });
    }
  }

  $(e.target).trigger("input");
};

var handleSpaceKey = function(e) {
  var replaceTo;
  var checkMark;
  var match;
  var currentLine;

  if (!(e.shiftKey && e.altKey)) {
    return;
  }

  if (!(currentLine = getCurrentLine(e))) {
    return;
  }

  if (match = currentLine.text.match(/^(\s*)(-|\+|\*) (?:\[(x| )\] )(.*)/)) {
    e.preventDefault();
    checkMark = (match[3] === " " ? "x" : " ");
    replaceTo = ("" + (match[1]) + (match[2]) + " [" + (checkMark) + "] " + (match[4]));

    $(e.target).selection("setPos", {
      start: currentLine.start,
      end: currentLine.end
    });

    $(e.target).selection("replace", {
      text: replaceTo,
      mode: "keep"
    });

    $(e.target).selection("setPos", {
      start: currentLine.caret,
      end: currentLine.caret
    });

    $(e.target).trigger("input");
  }
};

var getCurrentLine = function(e) {
  var text = $(e.target).val();
  var pos = $(e.target).selection("getPos");

  if (!text) {
    return null;
  }

  if (pos.start !== pos.end) {
    return null;
  }

  var startPos = text.lastIndexOf("\n", pos.start - 1) + 1;
  var endPos = text.indexOf("\n", pos.start);

  if (endPos === -1) {
    endPos = text.length;
  }

  return {
    text: text.slice(startPos, endPos),
    start: startPos,
    end: endPos,
    caret: pos.start,
    endOfLine: !$.trim(text.slice(pos.start, endPos))
  };
};

var getPrevLine = function(e) {
  var currentLine = getCurrentLine(e);
  var text = $(e.target).val().slice(0, currentLine.start);
  var startPos = text.lastIndexOf("\n", currentLine.start - 2) + 1;
  var endPos = currentLine.start;

  return {
    text: text.slice(startPos, endPos),
    start: startPos,
    end: endPos
  };
};
