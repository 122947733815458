jQuery(function() {
  var star;
  star = new Star();
});

class Star {
  constructor() {
    this.init();
  }

  init() {
    this.highlight_my_footprint();
    this.listen();
  }

  highlight_my_footprint() {
    var nickname = $("body").data("current-user-nickname");
    var $icon_footprint = $((".icon-footprint[data-user-nickname='" + (nickname) + "']"));
    $icon_footprint.addClass("my");

    if ($("body").hasClass("post")) {
      $icon_footprint.closest(".star-content").find(".edit-star .edit i").removeClass("hidden");
    }
  }

  listen() {
    $(document).on("turbo:load", this.highlight_my_footprint);
    this.listen_click_star();
    this.listen_post();
    this.listen_touch_end();
    this.listen_edit_star();
  }

  listen_click_star() {
    $(document).on("click", "a.post-star", e => {
      $("ul.stars").append("<li class=\"star\"><i class=\"icon-footprint pending\" /></li>");
    });
  }

  listen_post() {
    $(document).on("ajax:before", "a.post-star, a.put-star", e => {
      var selection;

      if (this.selection) {
        selection = this.selection;
        this.selection = "";
      } else {
        selection = $.selection("html");
      }

      var params = new URLSearchParams({
        content: selection
      });

      e.currentTarget.dataset.params = params.toString();
    });

    $(document).on("ajax:success", "a.post-star, a.put-star", e => {
      var html;

      if (html = e.originalEvent.detail[0]) {
        $(".star-gazers").html($(html).find("body").html());

        if ($("body").data("current-user-nickname")) {
          this.highlight_my_footprint();
        } else {
          $(".post-star[data-method=post]").replaceWith($(".hidden .post-star"));
        }

        setTimeout(function() {
          $("ul.stars.star-toggle .trigger:first").click();
        }, 400);
      }

      return;
    });

    $(document).on("ajax:error", "a.post-star, a.put-star", e => {
      window.location.reload();
    });
  }

  listen_touch_end() {
    $(document).on("touchend", e => {
      var selection = $.selection("html");

      if (selection) {
        this.selection = selection;
      }
    });
  }

  listen_edit_star() {
    $(document).on("click", ".edit-star .edit", e => {
      $(e.currentTarget).addClass("hidden").closest(".phrase").find(".text").addClass("edit-mode");
      $(".post-star").addClass("hidden");
      $(".put-star").removeClass("hidden");
    });
  }
}

Star.prototype.selection = "";
