jQuery(function() {
  var themeColor = new ThemeColor();

  $(document).on("turbo:load, turbo:render", function() {
    if (!$("body").hasClass("edit-theme-color")) {
      return;
    }

    themeColor.init();
  });
});

class ThemeColor {
  constructor() {}

  init() {
    this.listen();
    $("input[type=radio]:checked").change();
  }

  listen() {
    $(document).on("change", "input[type=radio]", e => {
      $("input[type=radio]").closest("li").removeClass("checked");
      $(e.currentTarget).closest("li").addClass("checked");
    });
  }
}
