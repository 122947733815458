document.addEventListener("turbo:load", function() {
  var timeout_id;

  timeout_id = setTimeout(function() {
    $("#flash_notice").html("");

    $(".alert-success").animate({
      height: 0,
      padding: 0
    }, 400, "swing", function() {
      $(".alert-success").remove();
    });
  }, 2000);
});
