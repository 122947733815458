jQuery(function() {
  var zapping;
  zapping = new Zapping();
});

class Zapping {
  constructor() {
    this.init();
  }

  init() {
    this.listen();
    this.listen_touch_end();
  }

  listen() {
    $(document).on("click", ".zapping-button a", e => {
      $(e.currentTarget).addClass("clicked");
    });

    $(document).on(
      "transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd",
      ".zapping-button a",
      function(e) {
        $(e.currentTarget).removeClass("clicked");
      }
    );

    $(document).on("click", ".zapping-button a:not([href^='#'])", e => {
      e.preventDefault();
      this.next_nicknames();
    });
  }

  listen_touch_end() {
    $(document).on("touchend", e => {
      var selection = $.selection();

      if (selection) {
        this.selection = selection;
      }
    });
  }

  next_nicknames() {
    if (this.nicknames.length === 0) {
      $.ajax({
        url: "/zappings",
        dataType: "json",

        success: json => {
          if (json.nicknames.length === 0) {
            Turbo.visit("/");
          } else {
            this.nicknames = json.nicknames;
            this.visit(this.nicknames.shift());
          }
        }
      });
    } else {
      this.visit(this.nicknames.shift());
    }
  }

  visit(nickname) {
    Turbo.visit(("/u/" + (nickname)));
  }
}

Zapping.prototype.selection = "";
Zapping.prototype.nicknames = [];
