jQuery(function() {
  var following;
  following = new Following();
});

class Following {
  constructor() {
    this.init();
  }

  init() {
    this.listen();
  }

  listen() {
    this.listen_follow();
    this.listen_following();
  }

  listen_follow() {
    $(document).on("ajax:success", this.follow_toggle_selector, e => {
      $(this.follow_toggle_selector).toggleClass("hidden");

      if (rack_env !== "test") {
        this.trigger_hover_event = false;
      }

      return;
    });
  }

  listen_following() {
    $(".followings-button .btn-info").on("mouseenter", e => {
      if (!this.trigger_hover_event) {
        return;
      }

      $(e.currentTarget).hide();
      $(".followings-button .btn-danger").show();
    });

    $(".followings-button .btn-info").on("mouseleave", e => {
      this.trigger_hover_event = true;
    });

    $(".followings-button .btn-danger").on("mouseleave", e => {
      $(e.currentTarget).hide();
      $(".followings-button .btn-info").show();
    });
  }
}

Following.prototype.trigger_hover_event = true;
Following.prototype.follow_toggle_selector = "form#follow, form#unfollow";
