jQuery(function() {
  $(document).on("click", "a.ignore", function(e) {
    e.preventDefault();
  });

  $(document).on("submit", "form.search_poem", function(e) {
    e.preventDefault();
    var nickname = $(this).find("input[name=\"nickname\"]").val().replace(/@/, "");

    if (!nickname) {
      return;
    }

    location.href = ("/u/" + (nickname));
  });

  $(document).on("click", ".star-toggle a.trigger", function(e) {
    e.preventDefault();
    $(e.currentTarget).closest(".star-container").find(".star-toggle").toggleClass("hidden");
  });

  $(document).on("click", ".star-toggle a.trigger.open", function(e) {
    var scroll_top;
    scroll_top = $("body").scrollTop();
  });

  $(document).on("click", ".star-toggle a.trigger.close", function(e) {
    var $li_post = $(e.currentTarget).closest(".js-user-post");

    if (!$li_post.find(".user-name:first").is(":onScreen")) {
      $(window).scrollTop($li_post.offset().top);
    }
  });

  if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
    if (window.navigator.language.match(/ja/)) {
      document.title = "ポエム";
    } else {
      document.title = "Poem";
    }
  }

  $(document).on("click", ".js-followee", function(e) {
    var url = $(e.currentTarget).attr("href");
    $(("a[href='" + (url) + "']")).removeClass("new-icon");
  });

  $(document).on("turbo:load", function() {
    if ($("blockquote.instagram-media").length > 0) {
      window.instgrm.Embeds.process();
    }
  });
});
